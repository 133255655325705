// extracted by mini-css-extract-plugin
export var btn = "style-guide-typography-module--btn--u8G+x";
export var common = "style-guide-typography-module--common--J-ghv";
export var container = "style-guide-typography-module--container--YRrKr";
export var element = "style-guide-typography-module--element--XMMF6";
export var example = "style-guide-typography-module--example--CLBR4";
export var fast = "style-guide-typography-module--fast--C1qa9";
export var h1 = "style-guide-typography-module--h1--xP5oe";
export var h2 = "style-guide-typography-module--h2--oJzia";
export var h3 = "style-guide-typography-module--h3--Cn4ct";
export var h4 = "style-guide-typography-module--h4--XhLGz";
export var h5 = "style-guide-typography-module--h5--4YPLs";
export var h6 = "style-guide-typography-module--h6--RLLiZ";
export var helpText = "style-guide-typography-module--help-text--U794K";
export var link = "style-guide-typography-module--link--ooda9";
export var nav = "style-guide-typography-module--nav--xaTQ+";
export var p = "style-guide-typography-module--p--Jg37D";
export var slow = "style-guide-typography-module--slow--Pcb4b";
export var transitionExample = "style-guide-typography-module--transition-example--2Myz6";